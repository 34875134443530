import React from "react";
import styled from "styled-components";

export const CardDiv = styled.div`
  @media (min-width: 600px) {
    margin: 0 25px 0 25px;
  }
`;

const WorkExperience = () => {
  const jobs = {
    DE: "Web Development Tutor & Poker Dealer, Bay101 Casino",
    PM: "Product Manager/ Frontend Developer, GoingVis",
    DM: "Multi-Unit Manager, Panda Express",
    GM: "General Manager, Panda Express",
  };

  return (
    <>
      <CardDiv className="card">
        <div className="card-body">
          <div
            className="card-title font-weight-bold"
            style={{ fontSize: "25px" }}
          >
            {jobs.DE}
          </div>
          <div className="card-subtitle mb-3 text-muted">
            June 2018 - Current
          </div>
          <div className="card-text">
            <ul>
              <li>
                Teach HTML, CSS, & Javascript to students one on one. A session
                is usually around 1 to 2 hours.
              </li>
              <li>
                Students learn the fundamental of the web. They build out static
                sites and then move on to smaller projects.
              </li>
              <li>
                I teach React to intermediate students. We pair it with firebase
                to build a full-scale application.{" "}
              </li>
            </ul>
            <section>
              <em>Other Work:</em>
              <ul>
                <li>
                  Poker is a lot of fun. Interacting with guests and keeping
                  them happy is essential
                </li>
                <li>Follow procedures and casino's guidelines </li>
              </ul>
            </section>
          </div>
        </div>
      </CardDiv>
      <CardDiv className="card mt-4">
        <div className="card-body">
          <div
            className="card-title font-weight-bold"
            style={{ fontSize: "25px" }}
          >
            {jobs.PM}
          </div>
          <div className="card-subtitle mb-3 text-muted">
            Oct 2016 - June 2018
          </div>
          <div className="card-text">
            <ul>
              <li>Market research, Planning and Executing</li>
              <li>Provide clear vision and Product Roadmap</li>
              <li>Recruit and work with engineers and designers</li>
              <li>
                Ruby on Rails 5, 3rd party API, real-time web sockets, action
                cables
              </li>
              <li>Social gaming + Biggest gaming database</li>
            </ul>
          </div>
        </div>
      </CardDiv>
      <CardDiv className="card mt-4" target="euro-finance">
        <div className="card-body">
          <div
            className="card-title font-weight-bold"
            style={{ fontSize: "25px" }}
          >
            {jobs.DM}
          </div>
          <div className="card-subtitle mb-3 text-muted">
            Aug 2015 - Oct 2016
          </div>
          <div className="card-text">
            <ul>
              <li>Revitalize and restructure a franchise store operation </li>
              <li>Talents recruitment and development</li>
              <li>Over 90% customer satisfaction</li>
              <li>P&L focus</li>
              <li>Open new location, managing 4 stores</li>
            </ul>
          </div>
        </div>
      </CardDiv>
      <CardDiv className="card mt-4">
        <div className="card-body">
          <div
            className="card-title font-weight-bold"
            style={{ fontSize: "25px" }}
          >
            {jobs.GM}
          </div>
          <div className="card-subtitle mb-3 text-muted">
            June 2013 - Aug 2015
          </div>
          <div className="card-text">
            <ul>
              <li>
                {" "}
                Manage and direct a team of 20, annual revenue of $2.4 million ∙
                Hire and develop future leaders and managers{" "}
              </li>
              <li>
                Cultivate an immersive culture that follows company mission and
                values
              </li>
              <li>Strong focus on P&L</li>
            </ul>
          </div>
        </div>
      </CardDiv>
      {/* TODO ADD OTHER JOB CARDS HERE */}
    </>
  );
};

export default WorkExperience;
