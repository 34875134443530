import React from "react";
import { CardDiv } from "./WorkExperience";

const Education = () => (
  <>
    <CardDiv className="card mt-4">
      <div className="card-body">
        <div
          className="card-title font-weight-bold"
          style={{ fontSize: "25px" }}
        >
          B.S. Degree in Business Management
        </div>
        <div className="card-subtitle mb-3 text-muted">
          San Jose State University, San Jose, CA
        </div>
      </div>
    </CardDiv>
    <CardDiv className="card mt-4">
      <div className="card-body">
        <div
          className="card-title font-weight-bold"
          style={{ fontSize: "25px" }}
        >
          A.A. Degree in Liberal Arts emph. Business and Computer Information
        </div>
        <div className="card-subtitle mb-3 text-muted">
          De Anza College, Cupertino, CA
        </div>
      </div>
    </CardDiv>
    <CardDiv className="card mt-4">
      <div className="card-body">
        <div
          className="card-title font-weight-bold"
          style={{ fontSize: "25px" }}
        >
          APIASF Scholar
        </div>
        <div className="card-subtitle mb-3 text-muted">
          The Asian & Pacific Islander American Scholarship Fund (APIASF).
          Washington D.C. Scholars have demonstrated a strong commitment to
          giving back and are working to make a difference in their communities.
        </div>
      </div>
    </CardDiv>
  </>
);

export default Education;
