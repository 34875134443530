import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import linkPreview from "../assets/link-preview.gif";
import daHomePage from "../assets/daHomePage.gif";

const Div = styled.div`
  max-width: 700px;
`;

const Home = () => (
  <Div className="container">
    <section className="mb-5">
      <h2 className="mb-4">Hi there, space cowboy!!</h2>
      <h5 className="mb-4">
        I like building things. I built my first desktop computer in the 7th
        grade and fell in love with computer ever since. I think engineering as
        a concept intrigues me. The ability to think of an idea and build it is
        very exciting. Thus I live in Silicon Valley, and we are all here to
        work on our dream. Like to work with startups.
      </h5>
    </section>
    <section className="mb-4">
      <h2 className="mb-4">Technologies I am currently interesting in </h2>
      <h5 className="text-left">
        <ul className="mt-3">
          <li>WebRTC</li>
          <li>Blockchain/Crypto Technologies</li>
          <li>InterPlanetary File System (IPFS)</li>
        </ul>
      </h5>
    </section>
    <section>
      <h2>My bread and butter</h2>
      <img
        width={75}
        src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/react/react.png"
        alt="React"
      />
      <img
        width={75}
        src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/firebase/firebase.png"
        alt="Firebase"
      />
    </section>
    <section className="mb-5">
      <h2 className="mb-4">React Projects</h2>
      <h5 className="m-4"> DaHomePage </h5>
      <Link target="_blank" to={"//dahomepage.com"}>
        <img src={daHomePage} class="img-fluid" alt="" />{" "}
      </Link>
      <br />
      <h5 className="m-4"> React Link Preview </h5>
      <Link target="_blank" to={"//react-link-preview.firebaseapp.com/"}>
        <img src={linkPreview} class="img-fluid" alt="" />{" "}
      </Link>
    </section>
  </Div>
);

export default Home;
